.single-taxi-fare-one .top-block {
    justify-content: unset !important;
}


ul.tick-list{
    padding-left:15px; 
  }
  
  ul.tick-list li{
  padding: 8px 0px 8px 20px;
  }
  
  ul.tick-list li:before
  {
  content: '✔';
  color:#82b808;
  font-size:120%;
  background:none;
  position: absolute;
  left: 0px;
  top: 8px;
  }