

.privacy-nav {
    width: 98%;
    border: 1px solid blue;
    background-color: #66ccff;
    padding: 15px;
    text-decoration: none;
    margin-top: 25px;
    font-size: 30px;
  }
  
  .privacy-section {
    margin-top: 25px;
    
  }
  
  .privacy-article {
    width: 70%;
    border: 1px solid black;
    background-color: #cccccc;
    padding: 15px;
    margin: 15%;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 22px
  }
  
   .privacy-article >p {
    font-size: 17px;
  }
    .privacy-article >h1 {
      text-align: center;
  }
  
  #social{
    width: 50%;
    border: 1px solid blue;
    background-color: #66ccff;
    padding: 15px;
    margin: 25%;
    margin-top: 3%;
    margin-bottom: 5%;
    font-size: 30px;
    
  }
  
