@import url("../../../node_modules/react-modal-video/css/modal-video.min.css");




 

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse-big
{
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
  }
}

.example-1
{
  /* float: left;
  margin: 40px;
  width: 130px;
  height: 130px;
  font: 13px/130px 'Barlow Semi Condensed', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  background: rgba(30, 144, 255, 0.6);
  border-radius: 50%; */
  animation: shadow-pulse 1s infinite;
}


.react-slideshow-container
.react-slideshow-zoom-wrapper{
  max-width: 100%;
  max-height: 600px;
}

.img-slide-client{
    width: 500px;
    object-fit: scale-down;
    max-height: 600px;
}

/* .about-image-block {
  border: 2px solid #FFC000;
} */
.zoom-wrapper>div >img {
  margin-left: 10%;
  /* border: 2px solid #FFC000; */
}


.download-me{
  margin-top: 30%;
  margin-bottom: 35%;
}

.ul {
  /* width: 100%; */
  text-align: center;
  /* margin: 0; */
  padding: 0;
  position: absolute;
  /* top: 50%; */
  transform: translateY(-50%);
}

.ul>li {
  display: inline-block;
  margin: 10px;
}

.download {
  width: 280px;
  height: 75px;
  background: black;
  float: left;
  border-radius: 20px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
}

.download > .fa {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.df,
.dfn {
  position: absolute;
  left: 70px;
}

.df {
  top: 20px;
  font-size: .68em;
}

.dfn {
  top: 33px;
  font-size: 1.6em;
}

.download:hover {
  background-color: #FFC000;
  /* -webkit-filter: invert(100%); */
  /* filter: invert(100%); */
}