

.center {
    text-align: center;
    }
    
    div#c1494234274795 {
        margin-top: -10px;
    }
    
    div#c1494234274795 > div > .module > .custom-module > div > .mod-wrp-1 {
        margin: 0px;
    }
    
    .fa {
        color: #d4c395;
    }
    
    .content--saints-mobile-app {
    text-align: left;
    padding: 20px;
    }
    
    .content--saints-mobile-app > p {
        margin-bottom: 16px;
    }
    
    .content--saints-mobile-app > p:first-of-type {
        font-size: 30px;
        line-height: 36px;
        font-family: "Bebas Neue";
    }
    
    .content--saints-mobile-app >h3 {
        margin-bottom: 16px;
    }
    
    ul.ul--mobile-app-features > li {
        margin-bottom: 16px;
    }
    
    ul.ul--mobile-app-features > li strong {
        font-size: 18px;
    }
    
    .full-width-header > img {
      width: 100%;
    }
    
    .block--saints-app-download-cta {
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
      font-family: oswald,sans-serif;
      font-weight: normal;
      font-size: 20px;
      /* border: 2px solid #90a4b2; */
      /* max-width: 50%; */
      border-bottom: 0px;
      position: relative;
      top: 22px;
    }
    
    .block--saints-app-download-cta > span {
        position: relative;
        top: -52px;
        font-size: 35px;
    }
    
    .block--saints-app-download-android {
        flex-basis: 50%;
        -webkit-flex-basis: 50%;
    }
    
    .btn--download-android > img {
      max-width: 300px;
      position: relative;
      z-index: 2; 
    }
    
    .block--saints-app-download-ios {
        flex-basis: 50%;
        -webkit-flex-basis: 50%;
    }
    
    .btn--download-ios > img {
        min-width: 263px;
          position: relative;
      z-index: 2; 
    }
    
    .block--saints-app-download-buttons {
        /* display: -webkit-box; */
        /* display: -webkit-flex; */
        /* display: -moz-box;
        display: -ms-flexbox;
        display: flex; */
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        /* background: #e2e8ee; */
        /* background: -moz-linear-gradient(top, #e2e8ee 0%, #dee5eb 50%, #8fa3b1 100%);
        background: -webkit-linear-gradient(top, #e2e8ee 0%,#dee5eb 50%,#8fa3b1 100%);
        background: linear-gradient(to bottom, #e2e8ee 0%,#dee5eb 50%,#8fa3b1 100%); */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e8ee', endColorstr='#8fa3b1',GradientType=0 );
        /* border: 2px solid #b3bfc7; */
        border-top: 0px;
        min-height: 150px;
        margin-top: -5px;
        padding: 60px 0px 30px 0px;
        text-align: center;
    }
    
    .mobile-app-sponsor {
        width: 100%;
        background: #383838;
        background: -moz-linear-gradient(top, #383838 25%, #1d1916 75%);
        background: -webkit-linear-gradient(top, #383838 25%,#1d1916 75%);
        background: linear-gradient(to bottom, #383838 25%,#1d1916 75%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#383838', endColorstr='#1d1916',GradientType=0 );
        overflow: hidden;
        display: flex; 
        justify-content: center;
        height: 150px;
        align-items: center;
        margin-top: -5px;
    }
    
    .wrap-mobile-app-feature {
        max-width: 537px;
        margin: auto;
        margin-bottom: 37px;
    }
    
    .mobile-app-feature-icon {
        float: left;
        font-size: 42px;
        margin-right: 30px;
    }
    
    .text-left {
        text-align: left;
        max-width: 600px;
        margin: auto;
    }
    
    .headline-features--medium {
        font-size: 30px;
        text-decoration: underline;
        font-family: "Bebas Neue";
        margin-bottom: 10px;
    }
    
    

    

ol, ul{
    padding-left:5px; /*override defult massive padding*/
    font-family:arial;
    font-size:14px;
  }
  li
  {
    list-style: none; 
    padding: 10px 0 10px 40px; /*40 is width of img + some space*/
    position: relative; 
  }
  ul li:before
  {
  content: '';
  background: url(https://www.learningpeople.co.uk/imgs/sprites.png);
    background-position: -38px -26px;
  height: 21px;
  width: 29px;
  position: absolute;
  left: 0;
  top: 8px;
  }
  
  
  
  ul.tick-list{
    padding-left:15px; 
  }
  
  ul.tick-list li{
  padding: 8px 0px 8px 20px;
  }
  
  ul.tick-list li:before
  {
  content: '✔';
  color:#82b808;
  font-size:120%;
  background:none;
  position: absolute;
  left: 0px;
  top: 8px;
  }