/*
* expandable menu started
*/


@media (max-width: 1199px) {


    .header-navigation {
        margin-bottom: 0;
    }

    .logo-box {
        width: 100%;
        float: none;
        margin: 0 !important;
    }

    .navigation-box {
        margin: 0;
    }

    .navbar-expand-lg .menu-toggler {
        display: block;
        margin-right: 0;
        border-color: #fff;
        background-color: transparent !important;
        margin: 23px 0;
        color: #fff;
        border: none;
        font-size: 28px;
        float: right;
        outline: none;
        cursor: pointer;
    }

    .menu-toggler:hover {
        border-color: #00AD4D;
    }

    .menu-toggler .icon-bar {
        background: #fff;
    }

    .menu-toggler:hover .icon-bar {
        background: #00AD4D;
    }

    .navbar-expand-lg .navbar-collapse {
        padding-bottom: 0;
        overflow: auto !important;
        width: 100%;
    }

    .navbar-expand-lg .navbar-collapse {
        display: none !important;
        background: transparent;
        padding: 0px 0px !important;
        padding-bottom: 0px !important;
        margin: 0;
        background: #000;
        transition: all .5s ease;
        height: 0;
    }

    .navbar-expand-lg .navbar-collapse.showen {
        display: block !important;
        height: auto;
        max-height: 70vh;
    }

    .navbar-collapse.show {
        overflow-y: auto;
    }

    .header-navigation .nav {
        width: 100%;
        text-align: left;
    }

    .header-navigation .nav>li {
        display: block;
        padding: 0 !important;
        width: 100%;
        float: none;
    }

    .header-navigation .nav>li+li {
        margin-left: 0 !important;
    }

    .header-navigation .nav>li>a {
        padding: 8px 0;
    }

    .header-navigation .right-box.nav {
        width: 100%;
        margin: 0;
    }

    .header-navigation .right-box.nav>li>a {
        border: none !important;
        padding: 8px 0;
        color: #fff;
    }

    .header-navigation .nav>li>a:after {
        display: none;
    }

    .header-navigation .nav.navigation-box+.nav {
        border-top: 1px dashed #282828;
    }

    .header-navigation .navigation-box>li>.sub-menu,
    .header-navigation .navigation-box>li>.sub-menu>li>.sub-menu {
        position: relative !important;
        width: 100% !important;
        opacity: 1 !important;
        visibility: visible !important;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        transition: none !important;
        display: none;
        float: none !important;
        margin: 0;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .header-navigation .navigation-box>li>.sub-menu>li>a::after {
        display: none;
    }

    .header-navigation ul.navigation-box>li+li {
        padding: 0 !important;
        border-top: 1px solid rgba(255, 255, 255, .1);
        display: block;
        float: none;
    }

    .header-navigation .nav>li.show-mobile {
        display: none;
    }

    .header-navigation ul.navigation-box>li>a,
    .header-navigation .right-box.nav>li>a {
        padding: 0;
        display: block;
        color: #fff;
        padding: 16px 0 16px 20px !important;
        transition: background 0.4s ease 0s;
    }

    .header .stricky-fixed.header-navigation .nav>li {
        padding: 0 !important;
    }

    .header-navigation ul.navigation-box li a .sub-nav-toggler {
        background-color: transparent;
        background-image: none;
        border: 1px solid #FFC000;
        border-radius: 4px;
        padding: 9px 9px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 15px;
        /*margin-top: 8px;*/
        margin-right: 0px;
        display: block;
        cursor: pointer;
    }

    .header-navigation .navigation-box .sub-nav-toggler .icon-bar {
        background-color: #FFC000;
        border-radius: 1px;
        display: block;
        height: 1px;
        width: 20px;
    }

    .header-navigation .navigation-box .sub-nav-toggler .icon-bar+.icon-bar {
        margin-top: 4px;
    }

    .header-navigation .container .right-side-box {
        top: 0px;
        right: 0px;
        transform: translateY(0%)
    }

    .header-navigation .container {
        display: block;
        padding-right: 0px;
        padding-left: 0px;
        position: relative;
    }

    .header-navigation .container .logo-box {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        float: none;
        padding-right: 145px;
    }

    .header-navigation .container .logo-box .navbar-brand {
        float: left;
        padding: 20px 0;
    }

    .header-navigation .container .logo-box .navbar-toggler {
        float: left;
    }

    .header-navigation ul.navigation-box {
        display: block;
        margin: 0 !important;
        width: 100%;
    }

    .header-navigation ul.navigation-box>li {
        padding: 0;
        display: block;
    }

    .header-navigation ul.navigation-box>li>a {
        display: block;
        padding: 13px 30px;
    }

    .header-navigation ul.navigation-box>li>a:after {
        display: none;
    }

    .header-navigation ul.navigation-box>li+li {
        margin: 0;
    }

    .header-navigation .main-navigation {
        float: none;
        width: 100%;
        display: none;
        text-align: left;
        background: #181818;
        max-height: 70vh;
        overflow-y: scroll;
    }

    .header-navigation .container .menu-toggler {
        display: block;
    }

    .header-navigation.stricky-fixed ul.navigation-box>li {
        padding: 0;
    }

    .header-navigation .container .logo-box .navbar-brand {
        background-color: transparent;
    }

    .header-navigation .container .menu-toggler {
        float: left;
        margin: 23px 0;
        color: #111111;
    }

    .header-navigation .container .logo-box {
        padding-right: 0 !important;
    }

    .header-navigation {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .site-header.header-one .header-navigation .right-side-box .contact-btn-block {
        padding-top: 18.5px;
        padding-bottom: 35.5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .site-header.header-one .header-navigation .container .logo-box {
        padding-left: 60px;
    }

    .site-header.header-one .header-navigation .container {
        padding-left: 0;
        overflow: hidden;
    }

    .site-header.header-one .header-navigation ul.navigation-box>li {
        padding: 0;
    }

    .site-header.header-one .header-navigation ul.navigation-box>li+li {
        margin-left: 0;
    }

    .header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
        background-color: #fff;
    }

    .site-header.header-one .header-navigation.stricky-fixed .container {
        overflow: visible;
    }

    .site-header.header-one .header-navigation.stricky-fixed .container .right-side-box .contact-btn-block {
        padding-top: 18.5px;
        padding-bottom: 18.5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
    }

    .site-header.header-two .header-navigation .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .site-header.header-two .header-navigation .container .menu-toggler {
        color: #FFC000;
    }

    .site-header.header-two .header-navigation ul.navigation-box>li {
        padding: 0;
    }

    .site-header.header-two .header-navigation .container .right-side-box {
        top: 30px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .site-header.header-one .header-navigation ul.navigation-box>li.current>a,
    .site-header.header-one .header-navigation ul.navigation-box>li:hover>a {
        color: #FFC000;
    }


}


@media(max-width: 320px) {}


@media(min-width: 1200px) {
    .about-image-block p {
        display: none;
    }
}

@media(max-width: 1199px) {
    .about-style-one .video-block-one {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .about-style-one hr.style-one {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .about-style-one .video-block-one .image-block {
        -webkit-box-ordinal-group: 1;
        order: 0;
        width: 100%;
        margin-bottom: 30px;
    }

    .about-style-one .video-block-one .image-block .inner-block {
        width: 100%;
    }

    .about-style-one .video-block-one .content-block {
        padding-bottom: 40px;
    }

    .about-style-one .text-block>p {
        display: none;
    }

    .about-style-one .high-gutter {
        margin-left: -15px;
        margin-right: -15px;
    }

    .about-style-one .high-gutter>[class*=col-] {
        padding-left: 15px;
        padding-right: 15px;
    }

    .about-style-one .call-block {
        flex-direction: column;
    }

    .about-style-one .call-block .left-block {
        margin-bottom: 20px;
    }

    .about-image-block p {
        display: block;
        margin-top: 40px;
    }

    .single-taxi-fare-one {
        padding: 50px;
    }

    .single-blog-style-one {
        margin-bottom: 30px;
    }

    .single-blog-style-two {
        flex-direction: column;
    }

    .single-blog-style-two .image-block {
        -webkit-box-ordinal-group: 1;
        order: 0;
        width: 100%;
    }

    .single-blog-style-two .image-block .inner-block {
        width: 100%;
    }

    .blog-style-two-row [class*=col-]+[class*=col-] {
        margin-top: 0px;
    }

    .single-blog-style-two .text-block {
        padding: 40px;
    }

    .about-style-three .content-block .block-title h2 {
        font-size: 40px;
    }

    .cta-style-three .content-block .app-btn {
        padding-left: 45px;
        padding-right: 30px;
    }

    .cta-style-three .content-block .app-btn i.icon {
        font-size: 20px;
        left: 20px;
    }

    .about-style-two .content-block .block-title br {
        display: none;
    }

    .block-title h2 {
        font-size: 45px;
    }

    .book-ride-one .content-block>p br {
        display: none;
    }

    .site-header.header-one .header-navigation .container .right-side-box {
        right: -1px;
    }

    .site-header.header-one .header-navigation.stricky-fixed .container .right-side-box .contact-btn-block {
        padding-top: 17.5px;
        padding-bottom: 34.5px;
    }

    .site-header.header-one .stricky-fixed.header-navigation .container .logo-box {
        padding-left: 0;
    }
}

@media(max-width: 991px) {
    .site-header.header-one .top-bar .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 110px;
    }

    .site-header.header-one .top-bar .logo-block {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        -o-transform: translate(0);
        transform: translate(0);
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .banner-style-one .banner-title {
        font-size: 50px;
        line-height: 60px;
    }

    .banner-style-one p {
        font-size: 20px;
        line-height: 30px;
    }

    .banner-style-one .slide {
        padding-bottom: 120px;
    }

    .book-ride-one .content-block {
        margin-bottom: 50px;
    }

    .about-image-block p {
        margin-bottom: 40px;
    }

    .cta-style-two .container {
        flex-direction: column;
        text-align: center;
    }

    .cta-style-two .container .button-block {
        margin-top: 40px;
    }

    .taxi-style-one {
        padding-bottom: 90px;
    }

    .single-taxi-one {
        margin-bottom: 30px;
    }

    .feature-style-one .row [class*=col-]:nth-child(2) {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        border-left: 0px solid rgba(255, 255, 255, 0.1);
        border-right: 0px solid rgba(255, 255, 255, 0.1);
        margin-top: 30px;
        margin-bottom: 30px;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .single-taxi-fare-one {
        padding: 100px;
    }

    .block-text p br {
        display: none;
    }

    .blog-style-two-row [class*=col-]+[class*=col-] {
        margin-top: 30px;
    }

    .site-footer .footer-widget {
        margin-bottom: 40px;
    }

    .site-footer .upper-footer {
        padding-bottom: 70px;
    }

    .site-header.header-two .top-bar .inner-container {
        flex-direction: column;
    }

    .site-header.header-two .top-bar .logo-block {
        margin-bottom: 15px;
    }

    .about-style-two .image-block {
        margin-top: 40px;
        margin-left: 0;
    }

    .history-style-one .history-carousel-block {
        margin-right: 0;
    }

    .history-style-one .block-title {
        margin-top: 40px;
    }

    .single-taxi-details-one .image-block {
        margin: 0;
        margin-bottom: 40px;
    }

    .sidebar {
        margin-top: 50px;
    }
}

@media(max-width: 767px) {
    img {
        max-width: 100%;
    }

    .site-header.header-one .top-bar {
        background-color: #7a6a6a;
    }

    .site-header.header-one {
        position: relative;
    }

    .banner-style-one {
        margin-top: -86px;
    }

    .banner-style-one .slide {
        padding: 100px 0;
        padding-top: 186px;
    }

    .taxi-style-one .tab-title {
        margin-bottom: 50px;
    }

    .taxi-style-one .tab-title li {
        margin-bottom: 10px !important;
    }

    .cta-style-one h3,
    .cta-style-two h3,
    .inner-banner h2,
    .block-title h2 {
        font-size: 40px;
    }

    .single-taxi-fare-one {
        padding: 40px;
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn.left-btn,
    .main-banner-wrapper .carousel-btn-block .carousel-btn.left-btn {
        left: 3%;
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn.right-btn,
    .main-banner-wrapper .carousel-btn-block .carousel-btn.right-btn {
        right: 3%;
    }

    .brand-carousel-one {
        padding: 50px 0;
    }

    .single-taxi-faq-one .container,
    .contact-form-style-one,
    .team-style-one,
    .cta-style-three .content-block,
    .offer-style-one,
    .blog-style-one,
    .testimonials-style-one,
    .taxi-fare-one,
    .feature-style-one,
    .taxi-style-one,
    .funfact-style-one,
    .about-style-one,
    .book-ride-one {
        padding-top: 65px;
    }

    .contact-form-style-one,
    .offer-style-one,
    .site-footer .upper-footer,
    .taxi-style-one,
    .funfact-style-one {
        padding-bottom: 50px;
    }

    .single-taxi-faq-one .container,
    .single-blog-details-page,
    .cta-style-three .content-block,
    .cta-style-one,
    .blog-style-one,
    .about-style-one {
        padding-bottom: 80px;
    }

    .feature-style-one,
    .cta-style-two {
        padding-bottom: 118px;
    }

    .team-style-one,
    .book-ride-one {
        padding-bottom: 98px;
    }

    .single-blog-details-page,
    .cta-style-three,
    .cta-style-one,
    .cta-style-two {
        padding-top: 80px;
    }

    .testimonials-style-one,
    .taxi-fare-one {
        padding-bottom: 70px;
    }

    .site-footer .upper-footer {
        padding-top: 118px;
    }

    .site-header.header-two .top-bar .contact-infos {
        flex-direction: column;
    }

    .banner-style-two .banner-circle {
        height: 510px;
    }

    .banner-style-two h3 {
        font-size: 50px;
        line-height: 60px;
    }

    .about-style-three .right-block .right-upper-block,
    .about-style-three .left-block {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .about-style-three .right-block .right-upper-block {
        padding-bottom: 64px;
    }

    .about-style-three .cta-block {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .inner-banner {
        padding-top: 206px;
        padding-bottom: 120px;
        margin-top: -86px;
    }

    .history-style-one,
    .about-style-two,
    .single-taxi-details-one,
    .blog-page {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .team-page {
        padding-top: 80px;
        padding-bottom: 50px;
    }
}

@media(max-width: 575px) {

    .single-blog-details-page .share-block,
    .site-footer .bottom-footer .inner-container {
        flex-direction: column;
    }

    .testimonials-style-one .testimonials-one-pager {
        margin-bottom: 30px;
    }

    .testimonials-style-one .testimonials-one-pager .pager-item {
        margin: 0 !important;
    }

    .testimonials-style-one .testimonials-one-pager .pager-item:not(.active) {
        display: none;
    }

    .testimonials-style-one .testimonials-one-slider-btn {
        text-align: center;
        width: 100%;
        margin-top: 30px;
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        -webkit-transform: translate(0);
        -ms-transform: translate(0);
        -o-transform: translate(0);
        transform: translate(0);
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn+.carousel-btn {
        margin-left: 15px;
    }

    .single-blog-details-page .share-block .social-block {
        margin-top: 15px;
    }

}

@media(max-width: 480px) {
    .banner-style-two .banner-circle {
        height: 450px;
    }

    .banner-style-two h3 {
        font-size: 40px;
        line-height: 50px;
    }

    .about-style-three .tag-line span {
        font-size: 35px;
    }

    .about-style-three .right-block .right-bottom-block .content-block {
        flex-direction: column;
    }

    .about-style-three .right-block .right-bottom-block .content-block .icon-block {
        margin-bottom: 15px;
    }

    .single-taxi-faq-one .accrodion-grp .accrodion .accrodion-title h4:before {
        right: -15px;
    }

    .main-banner-wrapper .carousel-btn-block .carousel-btn {
        position: relative;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        transform: translate(0);
    }

    .main-banner-wrapper .carousel-btn-block .carousel-btn+.carousel-btn {
        margin-left: 15px;
    }

    .main-banner-wrapper {
        position: relative;
    }

    .carousel-btn-block {
        position: absolute;
        bottom: 20px;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
        z-index: 99;
    }

    .banner-style-one .slide {
        padding-bottom: 155px;
    }
}

@media(max-width: 375px) {
    br {
        display: none;
    }

    .site-header.header-one .top-bar .left-block a {
        display: block;
        margin: 0 !important;
    }

    .banner-style-one .banner-title {
        font-size: 40px;
        line-height: 50px;
    }

    .banner-style-one p {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn,
    .main-banner-wrapper .carousel-btn-block .carousel-btn {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .testimonials-style-one .testimonials-one-slider-btn .carousel-btn i,
    .main-banner-wrapper .carousel-btn-block .carousel-btn i {
        line-height: 36px;
    }

    .single-testimonial-one p {
        font-size: 25px;
        line-height: 40px;
    }

    .site-footer .bottom-footer .inner-container .left-block {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
    }

    .site-header.header-one .header-navigation .right-side-box .contact-btn-block .icon-block {
        display: none;
    }

    .site-header.header-one .header-navigation .right-side-box .contact-btn-block {
        padding-left: 20px;
        padding-right: 20px;
    }

    .site-header.header-one .header-navigation .container .logo-box {
        padding-left: 30px;
    }

    .cta-style-one h3,
    .cta-style-two h3,
    .single-blog-details-page .single-blog-style-one .post-title,
    .block-title h2 {
        font-size: 30px;
        line-height: 1.45em;
    }

    .single-taxi-fare-one .fare-btn {
        padding-left: 31.5px;
        padding-right: 31.5px;
    }

    .single-taxi-fare-one .top-block {
        flex-direction: column;
    }

    .single-taxi-fare-one .top-block .text-block {
        padding-left: 0;
        padding-top: 15px;
    }

    .single-blog-style-one .text-block h3 {
        font-size: 20px;
    }

    .banner-style-two .banner-circle {
        height: 345px;
    }

    .banner-style-two h3 {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 15px;
    }

    .cta-style-three .content-block .app-btn+.app-btn {
        margin-left: 0;
        margin-top: 20px;
    }

    .single-blog-details-page .share-block {
        padding: 30px;
    }

    .single-blog-details-page .single-comment-one {
        flex-direction: column;
    }

    .single-blog-details-page .single-comment-one .text-block {
        padding-left: 0;
        padding-top: 40px;
    }

    .contact-page-map-wrapper .contact-info-block {
        max-width: 290px;
        left: 15px;
    }

    .about-style-three .cta-block a {
        font-size: 30px;
    }
}

@media(max-width: 320px) {
    .banner-style-two .banner-circle {
        height: 290px;
    }

    .banner-style-two h3 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
    }

    .banner-style-two .banner-btn {
        padding: 9.5px 35px;
        font-size: 14px;
    }

    .single-blog-details-page .share-block .left-block p a {
        margin-bottom: 10px;
    }
}