
@media (min-width: 1200px) 
{
    .container {
      max-width: 1500px !important;
    }
}


.wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
  }
  
  .wrapper a {
    display: inline-block;
    margin: 5px;
  }
  
  .wrapper a:first-child {
    margin-left: 0;
  }
  
  .slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
  }
  
  .slide {
    height: 70vh;
    background-size: cover !important;
  }
  
  .slide::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
      bottom: 0;
      left: 0;
  }
  
  .nextButton{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          background: url('https://image.flaticon.com/icons/svg/181/181669.svg') no-repeat center center / 26px;
          width: 32px;
          height: 32px;
          text-indent: -9999px;
          cursor: pointer;
  }

  .previousButton{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNMjU2LDBDMTE0LjgzNywwLDAsMTE0LjgzNywwLDI1NnMxMTQuODM3LDI1NiwyNTYsMjU2czI1Ni0xMTQuODM3LDI1Ni0yNTZTMzk3LjE2MywwLDI1NiwweiBNMzEzLjc0OSwzNDcuNTg0ICAgIGM4LjM0MSw4LjM0MSw4LjM0MSwyMS44MjQsMCwzMC4xNjVjLTQuMTYsNC4xNi05LjYyMSw2LjI1MS0xNS4wODMsNi4yNTFjLTUuNDYxLDAtMTAuOTIzLTIuMDkxLTE1LjA4My02LjI1MUwxNzYuOTE3LDI3MS4wODMgICAgYy04LjM0MS04LjM0MS04LjM0MS0yMS44MjQsMC0zMC4xNjVsMTA2LjY2Ny0xMDYuNjY3YzguMzQxLTguMzQxLDIxLjgyNC04LjM0MSwzMC4xNjUsMHM4LjM0MSwyMS44MjQsMCwzMC4xNjVMMjIyLjE2NSwyNTYgICAgTDMxMy43NDksMzQ3LjU4NHoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K') no-repeat center center / 26px;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    cursor: pointer;
}
  /*  */
  
  /* .previousButton:hover, .nextButton:hover {
    background: url('https://image.flaticon.com/icons/svg/181/181669.svg') no-repeat center center / 26px;
  } */
  
  /* .previousButton {
    left: 0;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
            transform: rotate(180deg) translateY(calc(50% + 0px));
  } */
  
  .previousButton:hover {
    /* left: -10px; */
  }
  
  .nextButton {
    right: 0;
  }
  
  .nextButton:hover {
    /* right: -10px; */
  }
  
  .slider-content {
    text-align: center;
  }
  
  .slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  
  .slider-content .inner button{
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    font-size: 64px;
    line-height: 1;
  }
  
  .slider-content .inner p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
  }
  
  .slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .slider-content section span {
    color: #FFFFFF;
  }
  
  .slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  .slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
  }
  
  .slider-content section span strong {
    color: #FFFFFF;
    font-size: 14px;
    display: block;
  }
  
  @media (max-height: 500px) {
    .slider-wrapper, .slide {
      height: calc(100vh - 75px);
    }    
  }
  
  @media (max-width: 640px) {
    .slider-wrapper, .slide {
      height: calc(80vh - 75px);
    }    
  }
  
  @media (max-height: 600px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }    
  }
  
  @media (max-width: 640px) {
    .slider-content .inner h1 {
      font-size: 32px;
    }
  }



  .slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
					transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	-webkit-transition-delay: 1.5s;
					transition-delay: 1.5s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
					transition-delay: .3s;
}

.slide.animateOut p {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

.slide.animateOut section span {
	-webkit-transition-delay: .1s;
					transition-delay: .1s;
}

.slide.animateOut section span strong {
	-webkit-transition-delay: 0s;
					transition-delay: 0s;
}






ol, ul{
  padding-left:5px; /*override defult massive padding*/
  font-family:arial;
  font-size:14px;
}
li
{
  list-style: none; 
  padding: 10px 0 10px 40px; /*40 is width of img + some space*/
  position: relative; 
}
ul li:before
{
content: '';
background: url(https://www.learningpeople.co.uk/imgs/sprites.png);
  background-position: -38px -26px;
height: 21px;
width: 29px;
position: absolute;
left: 0;
top: 8px;
}



ul.tick-list{
  padding-left:15px; 
}

ul.tick-list li{
padding: 8px 0px 8px 20px;
}

ul.tick-list li:before
{
content: '✔';
color:#82b808;
font-size:120%;
background:none;
position: absolute;
left: 0px;
top: 8px;
}