/*** ACCORDION ***/

/* Header */
.accordion > div > div:first-of-type {
  padding: 1em;
  background-color: #4abdac;
  color: white;
  text-transform: uppercase;
  user-select: none;
  font-weight: 200;
  cursor: pointer;
  border-radius: 0.5em;
  box-shadow: 0 -10px 30px 2px rgba(0, 0, 0, 0.1) inset;
}
.accordion > div > div:first-of-type:hover {
  background-color: #59C3B3;
}

/* Content */
.accordion > div > div:last-of-type {
  background-color: #f9f9f9;
  margin: 0 0.5em;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
}

/* Inner */
.accordion .accordion-item {
  padding: 1em;
  opacity: 0;
  transition: opacity 300ms ease;
  overflow: auto;
}
.accordion > div:first-child .accordion-item {
  max-height: 400px;
}
.accordion > div.open .accordion-item {
  opacity: 1;
  transition: opacity 700ms ease;
}
.accordion img.accordion-item {
  padding: 0;
  margin: 0;
  width: 100%;
}