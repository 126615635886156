.team-page {
    background-color: #e6ffff;
    padding-bottom: 80px;
}

.image-size {
    max-width: 100%;
    max-height: 400px;
    height: 400px;
    object-fit:cover;
    width: 100%;
    border-radius: 5px;
}

div.polaroid {
    width: 100%;
    height: 500px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
    border-radius: 5px;
  }
  
  div.container-driver {
    text-align: center;
    padding: 10px 20px;
  }

 .polaroid:hover {
    border: solid 1px #CCC;
    -moz-box-shadow: 1px 1px 5px #999;
    -webkit-box-shadow: 1px 1px 5px #999;
        box-shadow: 1px 1px 5px #999;
}

