/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper{
    width: 100%;
}


.css-1uccc91-singleValue {
  color: #B5B5B5 !important;
  margin-left: 20px !important;
  font-size: 20px !important;
  /* margin-right: 2px; */
  /* max-width: calc(100% - 8px); */
  /* overflow: hidden; */
  /* position: absolute; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* top: 50%; */
  /* -webkit-transform: translateY(-50%); */
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-40%) !important;
  /* box-sizing: border-box; */
}

.css-1okebmr-indicatorSeparator {
   align-self: unset !important ; 
    -ms-flex-item-align: stretch;
    background-color: transparent !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    width: 1px !important;
    box-sizing: border-box ;
}

.css-1pahdxg-control,
.css-yk16xz-control {
  background-color: #212529 !important;
  border-radius: 100px !important;
  border-color: transparent !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 0px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  max-height: 70px !important;
}
.css-1pahdxg-control:hover{
  border-color: transparent !important;
}

div[class*="singleValue"] {
  width: 100%;
  height: 50%;
  padding-left: 8px;
  color: #B5B5B5;
  font-size: 15px !important;
  font-weight: 600;
}

div[class*="menu"] {
  background-color: #242424;
  color: #B5B5B5;
  font-size: 15px !important;
  font-weight: 600;
}