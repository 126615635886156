@font-face {
  font-family: 'conexi-icon';
  src:  url('fonts/conexi-icon.eot?k43ljg');
  src:  url('fonts/conexi-icon.eot?k43ljg#iefix') format('embedded-opentype'),
    url('fonts/conexi-icon.ttf?k43ljg') format('truetype'),
    url('fonts/conexi-icon.woff?k43ljg') format('woff'),
    url('fonts/conexi-icon.svg?k43ljg#conexi-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="conexi-icon-"], [class*=" conexi-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'conexi-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.conexi-icon-honda:before {
  content: "\e90f";
}
.conexi-icon-chevrolet:before {
  content: "\e910";
}
.conexi-icon-audi:before {
  content: "\e911";
}
.conexi-icon-left:before {
  content: "\e90b";
}
.conexi-icon-right:before {
  content: "\e90c";
}
.conexi-icon-consent:before {
  content: "\e900";
}
.conexi-icon-seatbelt:before {
  content: "\e901";
}
.conexi-icon-insurance:before {
  content: "\e902";
}
.conexi-icon-toyota:before {
  content: "\e903";
}
.conexi-icon-mercedes-benz:before {
  content: "\e904";
}
.conexi-icon-bmw:before {
  content: "\e905";
}
.conexi-icon-happy:before {
  content: "\e906";
}
.conexi-icon-taxi:before {
  content: "\e907";
}
.conexi-icon-team:before {
  content: "\e908";
}
.conexi-icon-meter:before {
  content: "\e909";
}
.conexi-icon-phone-call:before {
  content: "\e90a";
}
.conexi-icon-clock:before {
  content: "\e90d";
}
.conexi-icon-small-calendar:before {
  content: "\e90e";
}
